/* eslint-disable no-undef */
import {
    signIn,
    signInAdmin,
    signUp,
    recoverPassword,
    signUpFree,
    checkOtp,
    resendOtp,
    setPasswordFree,
    resetPassword,
    fetchUserInfo
} from '../Dal/AuthRepo'
import ToastManager from '../ToastManager'
import { parseJwt } from '~/fc/helpers'
import { resetWidget, updateFreshDeskUserData } from '~/fc/FreshDesk'


class AuthManager {
    constructor(ctx) {
        this.context = ctx
        this.store = this.context.$store
        this.router = this.context.$router
        this.auth = this.context.$auth
        this.toastManager = new ToastManager(this.context)
    }

    async saveUserInfo() {
        const { data } = await fetchUserInfo(this.auth.Token)
        this.auth.setUser(data.currentUser)
    }

    async signIn(authData) {
        try {
            const { data } = await signIn(authData)
            this.saveAuth(data, authData.remember)
            await this.saveUserInfo()
            this.toastManager.add('success', 'Login Successful', this.context.$t('login_success_msg'))
            const expires = (new Date(Date.now() + 86400 * 1000))
            $nuxt.$cookies.set('auth-token', data.token, { secure: false, expires, path: '/' })
            this.context.$store.commit('setLoggedIn', true)
            const redirect = window.localStorage.getItem('redirect')
            window.location.href = this.context.localePath(redirect || '/')
            updateFreshDeskUserData()
            resetWidget()
        } catch (error) {
            const errors = error.response ? error.response.data.errors : []
            errors.forEach((e) => {
                this.toastManager.add('danger', 'Login Failed', (e.langIdentifier ? this.context.$t(e.langIdentifier) : e.message))
            })
        }
    }

    async softLogIn(data) {
        try {
            this.saveAuth(data, true)
            await this.saveUserInfo()
            const expires = (new Date(Date.now() + 86400 * 1000))
            $nuxt.$cookies.set('auth-token', data.token, { secure: false, expires, path: '/' })
            this.context.$store.commit('setLoggedIn', true)
            this.context.$store.dispatch("getSubscription", data.token);
            updateFreshDeskUserData()
            resetWidget()
        } catch (error) {
            const errors = error.response ? error.response.data.errors : []
            errors.forEach((e) => {
                this.toastManager.add('danger', 'Login Failed', (e.langIdentifier ? this.context.$t(e.langIdentifier) : e.message))
            })
        }
    }

    async signInAdmin(token, authData) {
        try {
            const { data } = await signInAdmin(token, authData)
            this.saveAuth(data, authData.remember)
            await this.saveUserInfo()
            this.toastManager.add('success', 'Login Successful', this.context.$t('login_success_msg'))
            window.location.href = this.store.state.redirect ? this.store.state.redirect : '/account'
        } catch (error) {
            const errors = error.response ? error.response.data.errors : []
            errors.forEach((e) => {
                this.toastManager.add('danger', 'Login Failed', (e.langIdentifier ? this.context.$t(e.langIdentifier) : e.message))
            })
        }
    }

    async signUp(authData) {
        try {
            const data = await signUp(authData)
            this.saveAuth(data.data, false)
            await this.saveUserInfo()
            this.toastManager.add('success', 'Sign Up Successful', this.context.$t('login_success_msg'))
            const expires = (new Date(Date.now() + 86400 * 1000))
            $nuxt.$cookies.set('auth-token', data.data.token, { secure: false, expires, path: '/' })
            this.context.$store.commit('setLoggedIn', true)

            setTimeout(() => { this.context.$router.push(this.context.localePath('/checkout')) }, 500)
            resetWidget()
        } catch (error) {
            const errors = error.response ? error.response.data.errors : []
            errors.forEach((e) => {
                this.toastManager.add('danger', 'Sign Up Failed', (e.langIdentifier ? this.context.$t(e.langIdentifier) : e.message))
            })
        }
    }

    async signUpFree(authData) {
        try {
            const { data } = await signUpFree(authData)
            if (!data.token) {
                this.toastManager.add('danger', 'Sign Up Failed', this.context.$t('unable_to_sign_up'))
                return false
            }
            localStorage.setItem('temp-token', data.token)
            this.toastManager.add('success', 'Request sent!', data.message)
            return true
        } catch (error) {
            const errors = error.response ? error.response.data.errors : []
            errors.forEach((e) => {
                this.toastManager.add('danger', 'Sign Up Failed', (e.langIdentifier ? this.context.$t(e.langIdentifier) : e.message))
            })
            return false
        }
    }

    async checkOtp(authData) {
        try {
            const tempToken = localStorage.getItem('temp-token')

            const data = await checkOtp(authData, { Authorization: `Bearer ${tempToken}` })

            if (!(data.data.email && data.data.token)) { return }

            localStorage.setItem('temp-token', data.data.token)
            localStorage.setItem('temp-email', data.data.email)
            this.router.push(this.context.localePath('/auth/free/password-set'))
        } catch (error) {
            const errors = error.response ? error.response.data.errors : []
            errors.forEach((e) => {
                this.toastManager.add('danger', 'Sign Up Failed', (e.langIdentifier ? this.context.$t(e.langIdentifier) : e.message))
            })
        }
    }

    async resendOtp(authData) {
        try {
            const tempToken = localStorage.getItem('temp-token')
            const data = await resendOtp(authData, { Authorization: `Bearer ${tempToken}` })

            if (!(data.data.email && data.data.token)) { return }
            localStorage.setItem('temp-token', data.data.token)
            this.toastManager.add('success', 'Request Sent', this.context.$t('request_sent_check_email'))
        } catch (error) {
            const errors = error.response ? error.response.data.errors : []
            errors.forEach((e) => {
                this.toastManager.add('danger', 'Request Failed', (e.langIdentifier ? this.context.$t(e.langIdentifier) : e.message))
            })
        }
    }

    async recoverPassword(authData) {
        try {
            const { data } = await recoverPassword(authData)
            if (data.message === 'ok') { return false }
            return true
        } catch (error) {
            this.context.requestSent = false
            const errors = error.response ? error.response.data.errors : []
            errors.forEach((e) => {
                this.toastManager.add('danger', 'Request Failed', (e.langIdentifier ? this.context.$t(e.langIdentifier) : e.message))
            })
            return false
        }
    }

    async setPasswordFree(authData) {
        try {
            const tempToken = localStorage.getItem('temp-token')

            const { data } = await setPasswordFree(authData, { Authorization: `Bearer ${tempToken}` })

            this.saveAuth({ token: data.token })

            await this.saveUserInfo()

            localStorage.removeItem('temp-token')
            localStorage.removeItem('temp-email')

            location.href = '/account'
        } catch (error) {
            const errors = error.response ? error.response.data.errors : []
            errors.forEach((e) => {
                this.toastManager.add('danger', 'Login Failed', (e.langIdentifier ? this.context.$t(e.langIdentifier) : e.message))
            })
        }
    }

    async resetPassword(authData) {
        try {
            await resetPassword(authData)
            this.toastManager.add('success', 'Password changed successfully', this.context.$t('password_change_success'))
            this.router.push(this.context.localePath('auth/login'))
        } catch (error) {
            const errors = error.response ? error.response.data.errors : []
            errors.forEach((e) => {
                this.toastManager.add('danger', 'Password Change Failed', (e.langIdentifier ? this.context.$t(e.langIdentifier) : e.message))
            })
        }
    }

    // eslint-disable-next-line require-await
    async logOut() {
        try {
            this.auth.logOut()
            this.clearUserRelatedData()
            this.toastManager.add('success', 'Successfully Logged out', this.context.$t('logout_msg'))
            this.redirectAfterLogout()
        } catch (e) { }
    }

    clearUserRelatedData() {
        document.cookie = 'auth-token=; expires=Thu, 01 Jan 1970 00:00:01 GMT'
        $nuxt.$cookies.remove('auth-token', { path: '/' })
        $nuxt.$cookies.set("pro_user", false, {
            secure: false,
            expires: new Date(Date.now() + 86400 * 1000),
            path: "/",
        });
        this.context.$store.commit('setLoggedIn', false)
    }

    clearFilesData() {
        this.context.$store.commit('clear')
        this.context.$store.commit('items/clear')
        this.context.$store.commit('advancedSetting/clearOptions')
    }

    redirectAfterLogout() {
        if (this.context.$route.path.includes('checkout')) {
            window.top.location = this.context.localePath('/')
            return
        }
        if (this.context.$route.path.includes('account')) {
            this.router.push(this.context.localePath('/auth/login'))
            return
        }
        if (this.context.$route.path.includes('pricing')) {
            this.router.push(this.context.localePath('/auth/login'))
            return
        }
        location.reload()
    }

    saveAuth(data, remember) {
        if (data.token) { this.auth.setToken(data.token, data.user) }
        if (data.user) { this.auth.setUser(data.user) }
    }

    checkExpiryOfToken() {
        const token = this.auth.Token
        if (!token) { return }

        const jwt = parseJwt(token)
        const exp = jwt.exp
        const expired = Date.now() >= exp * 1000

        if (expired) { this.logOut() }
    }
}

export default AuthManager
